import { useState, useEffect } from 'react';

import { Background, Content, Offset, Row } from './styles';

import type { ModalOffsetInterface } from './interface';

const ModalOffset = ({
    isOpen: isOpenOutside = false,
    onCloseModal: onCloseModalOutside = () => null,
    size,
    children,
}: ModalOffsetInterface) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(isOpenOutside);
    }, [isOpenOutside]);

    function onCloseModal() {
        onCloseModalOutside();
        setIsOpen(false);
    }

    return (
        <Background className={`modal-content ${isOpen ? 'is-open' : ''}`}>
            <Offset onClick={() => onCloseModal()} />
            <Row>
                <Offset onClick={() => onCloseModal()} />
                <Content
                    className={`modal-content ${isOpen ? 'is-open' : ''}`}
                    size={size || 'md'}
                >
                    {children}
                </Content>
                <Offset onClick={() => onCloseModal()} />
            </Row>
            <Offset onClick={() => onCloseModal()} />
        </Background>
    );
};

export default ModalOffset;
