import logoUffs from 'assets/logoUFFS.png';
import { DefaultWrap } from './styles';

import type { LayoutDefaultInterface } from './interface';

const DefaultLayout = ({ children }: LayoutDefaultInterface) => {
  return (
    <DefaultWrap>
      <img
        className="logo"
        src={logoUffs}
        alt="chamas brancas com a escrita universidade federal da fronteira sul,  logo da universidade"
      />
      <main>{children}</main>

      <footer>
        <span>Discentes: Danubia W. Bueno e Felipe S. Nagel</span>
        <span>
          Trabalho da NP1 - Calculadora de Distribuição Binomial Comum e
          Acumulada
        </span>
        <span>
          Probabilidade e Estátistica - Notorno 2022/1 - Docente. Dr. Leandro
          Bordin{' '}
        </span>
      </footer>
    </DefaultWrap>
  );
};

export default DefaultLayout;
