import { SkeletonInterface } from './interface';

import { SkeletonWrap } from './styles';

const Skeleton = ({
    active = true,
    duration = 2000,
    height,
    width = '100%',
}: SkeletonInterface) => {
    if (active) {
        return (
            <SkeletonWrap
                className="skeleton"
                duration={duration}
                height={height}
                width={width}
            >
                <div className="shimmer-placeholder" />
            </SkeletonWrap>
        );
    }

    return null;
};

export default Skeleton;
