import { AiOutlineClose } from 'react-icons/ai';

import Offset from './Offset';

import Card from '../Card';

import type { ModalInterface } from './interface';

const Modal = ({
    isOpen: isOpenOutside = false,
    onCloseModal: onCloseModalOutside = () => null,
    size = 'md',
    children,
    title,
    subTitle,
    footer,
}: ModalInterface) => (
    <Offset
        isOpen={isOpenOutside}
        onCloseModal={onCloseModalOutside}
        size={size}
    >
        <Card
            headerTitle={title}
            headerSubTitle={subTitle}
            footer={footer}
            headerToolbar={
                <button type="button" onClick={() => onCloseModalOutside()}>
                    <AiOutlineClose />
                </button>
            }
        >
            {children}
        </Card>
    </Offset>
);

export default Modal;
