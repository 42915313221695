import InputCapsule from '../InputCapsule';
import { checkEmpty } from 'utils';

import { SelectWrap } from './styles';

import type { SelectInterface } from './interface';

const Select = ({
  id,
  label,
  isRequired,
  name,
  placeholder,
  value,
  onChange = () => null,
  options,
  style,
}: SelectInterface) => (
  <SelectWrap className="select-molecule">
    <InputCapsule
      htmlFor={id || name}
      label={label}
      isRequired={isRequired}
      empty={checkEmpty(value)}
      style={style}
    >
      <select
        id={id || name}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={(event) => onChange(event.target.value)}
      >
        {!checkEmpty(placeholder) && checkEmpty(value) && (
          <option value="">{placeholder}</option>
        )}
        {Array.isArray(options) &&
          options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>
    </InputCapsule>
  </SelectWrap>
);

export default Select;
