import { useEffect, useState } from 'react';

import { MySearchWrap } from './styles';
import Button from '../../Button';
import InputText from '../../Forms/InputText';

import type { MySearchInterface } from './interface';

const MySearch = ({ onSearch = () => null }: MySearchInterface) => {
  const [text, setText] = useState('');

  function handleFilter() {
    onSearch(text);
  }

  useEffect(() => {
    handleFilter();
  }, [text]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MySearchWrap className="my-search">
      <InputText
        name="search-text"
        onChange={setText}
        value={text}
        placeholder="Digite para Filtrar"
      />
      <Button model="light" onClick={() => setText('')}>
        Limpar
      </Button>
    </MySearchWrap>
  );
};

export default MySearch;
