import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import { Button, Card, InputNumber, Modal, Select, Table } from 'components';
import { convertFloatToBR, sweetAlert } from 'utils';

import { HomeWrap } from './styles';

const Home = () => {
  const theme = useTheme();

  const [isCalculated, setIsCalculated] = useState(false);

  const [valueN, setValueN] = useState(0);
  const [valueX, setValueX] = useState(0);
  const [valueP, setValueP] = useState(0);
  const [operatorX, setOperatorX] = useState('=');

  const [isOpenModalDetail, setIsOpenModalDetail] = useState(false);

  const [result, setResult] = useState(0);
  const [accumulatorResult, setAccumulatorResult] = useState<
    {
      n: number;
      x: number;
      p: number;
      q: number;
      result: number;
      accumulator: number;
    }[]
  >([]);

  useEffect(() => {
    setIsCalculated(false);
  }, [valueN, valueX, valueP, operatorX]);

  const cleanCalculator = () => {
    setIsCalculated(false);

    setValueN(0);
    setValueX(0);
    setValueP(0);
    setOperatorX('=');
    setResult(0);
    setAccumulatorResult([]);
  };

  const simpleFactorialize = (n: number): any => {
    if (n > 1) {
      return n * simpleFactorialize(n - 1);
    }
    return n;
  };

  const memo = (fn: any) => {
    const cache = new Map();
    return (n: number) => {
      if (cache.has(n)) {
        return cache.get(n);
      } else {
        const result = fn(n);
        cache.set(n, result);
        return result;
      }
    };
  };

  const factorialize = memo(simpleFactorialize);

  const elementCombination = (n: number, x: number) => {
    if (n === x || x === 0) {
      return 1;
    }

    return factorialize(n) / (factorialize(n - x) * factorialize(x));
  };

  const binomialDistribution = (
    n: number,
    x: number,
    pp: number,
    accumulator: number = 0
  ) => {
    const p = pp / 100;
    const q = 1 - p;

    const calculatedResult =
      elementCombination(n, x) * Math.pow(p, x) * Math.pow(q, n - x);

    return {
      n,
      x,
      p,
      q,
      result: calculatedResult,
      accumulator: accumulator + calculatedResult,
    };
  };

  const handleSubmit = () => {
    if (valueX > valueN) {
      sweetAlert.fire({
        title: 'Dados Inválidos!',
        text: 'O valor de X deve ser menor ou igual a N',
        icon: 'error',
        confirmButtonColor: theme.colors.base.primary,
      });

      return null;
    }

    setAccumulatorResult([]);

    if (operatorX === '=') {
      const calculateValue = binomialDistribution(valueN, valueX, valueP);

      setAccumulatorResult([calculateValue]);
      setResult(calculateValue.result);
    } else {
      let accumulatorArray: any = [];
      let accumulator = 0;

      let arrayNumbers = Array.from(Array(valueN + 1).keys());

      arrayNumbers = arrayNumbers.filter((number) =>
        operatorX === '>=' ? number >= valueX : number <= valueX
      );

      arrayNumbers.forEach((number) => {
        const calculateValue = binomialDistribution(
          valueN,
          number,
          valueP,
          accumulator
        );
        accumulator = calculateValue.accumulator;
        accumulatorArray.push(calculateValue);
      });

      setAccumulatorResult(accumulatorArray);
      setResult(accumulatorArray[accumulatorArray?.length - 1].accumulator);
    }

    setIsCalculated(true);
  };

  return (
    <HomeWrap>
      <Card
        headerTitle="Calculadora de Distribuição Binomial"
        footer={
          <>
            <Button
              type="button"
              model="light"
              onClick={() => cleanCalculator()}
              style={{ marginRight: theme.spacing[4] }}
            >
              Limpar
            </Button>
            <Button type="button" onClick={() => handleSubmit()}>
              Calcular
            </Button>
          </>
        }
      >
        <InputNumber
          name="value-n"
          placeholder="Informe valor do N"
          label="N (número de tentativas)"
          value={valueN}
          onChange={setValueN}
          style={{ marginBottom: theme.spacing[6] }}
          precision={0}
        />

        <InputNumber
          name="value-x"
          placeholder="Informe valor do X"
          label="X (número de sucesso)"
          value={valueX}
          onChange={setValueX}
          style={{ marginBottom: theme.spacing[6] }}
          precision={0}
        />

        <InputNumber
          name="value-p"
          placeholder="Informe valor do P"
          label="P em % (probabilidade de sucesso) "
          value={valueP}
          onChange={setValueP}
          postfix="%"
          min={0}
          max={100}
          style={{ marginBottom: theme.spacing[6] }}
        />

        <Select
          name="operator-x"
          placeholder="Operador de X"
          label="operador de x"
          value={operatorX}
          onChange={setOperatorX}
          options={[
            { label: `X = ${valueX}`, value: '=' },
            { label: `X ≥ ${valueX}`, value: '>=' },
            { label: `X ≤ ${valueX}`, value: '<=' },
          ]}
        />
      </Card>

      {isCalculated && (
        <Card
          style={{ marginTop: theme.spacing[6] }}
          headerTitle="Resultado"
          footer={
            <Button
              type="button"
              model="light"
              onClick={() => setIsOpenModalDetail(true)}
            >
              Exibir Detalhes
            </Button>
          }
        >
          <div className="result">
            Resultado Absoluto: <strong>{convertFloatToBR(result, 4)}</strong>
            <br />
            Resultado Relativo:{' '}
            <strong>{convertFloatToBR(result * 100, 2)}%</strong>
          </div>
        </Card>
      )}

      <Modal
        title="Detalhes"
        isOpen={isOpenModalDetail}
        onCloseModal={() => setIsOpenModalDetail(false)}
        size="xl"
      >
        <div style={{ overflowX: 'auto' }}>
          <Table
            data={accumulatorResult}
            columns={[
              {
                dataField: 'n',
                text: 'N',
              },
              {
                dataField: 'x',
                text: 'X',
              },
              {
                dataField: 'p',
                text: 'P',
                formatter: (value) => `${convertFloatToBR(value * 100, 2)}%`,
              },
              {
                dataField: 'q',
                text: 'Q',
                formatter: (value) => `${convertFloatToBR(value * 100, 2)}%`,
              },

              {
                dataField: 'result',
                text: 'Resultado',
                formatter: (value) => convertFloatToBR(value, 4),
              },

              {
                dataField: 'accumulator',
                text: 'Acumulado',
                formatter: (value) => convertFloatToBR(value, 4),
              },
            ]}
          />
        </div>
      </Modal>
    </HomeWrap>
  );
};

export default Home;
