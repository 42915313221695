import styled from 'styled-components';

export const TableWrap = styled.div`
    width: 100%;

    table {
        width: 100%;
        thead {
            border-bottom: ${(props) => props.theme.borderSize.default} solid
                ${(props) => props.theme.colors.base.gray200};
            th {
                padding: ${(props) => props.theme.spacing[7]};

                &:focus {
                    outline: 0;
                }

                &.sort {
                    cursor: pointer;
                }

                .content-header {
                    font-size: ${(props) => props.theme.fontSizes[6]};
                    font-weight: 400;
                    line-height: 120%;
                    text-align: left;
                    word-wrap: break-word;
                    color: ${(props) => props.theme.colors.text.gray800};

                    display: flex;
                    align-items: center;

                    .carets {
                        display: flex;
                        flex-direction: column;
                        margin-left: ${(props) => props.theme.spacing[2]};

                        svg {
                            font-size: ${(props) => props.theme.spacing[3]};

                            &:last-child {
                                margin-top: -${(props) => props.theme.spacing[1]};
                            }
                        }

                        .active {
                            fill: ${(props) => props.theme.colors.base.primary};
                        }
                    }
                }
            }
        }

        tbody {
            tr {
                border-bottom: ${(props) => props.theme.borderSize.default}
                    solid ${(props) => props.theme.colors.base.gray200};

                td {
                    padding: 0 ${(props) => props.theme.spacing[7]};

                    font-size: ${(props) => props.theme.fontSizes[6]};
                    font-weight: 400;
                    line-height: 120%;
                    text-align: left;
                    word-wrap: break-word;
                    color: ${(props) => props.theme.colors.text.gray800};

                    vertical-align: middle;
                    height: ${(props) => props.theme.spacing[20]};
                }
            }
        }
    }

    .footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: ${(props) => props.theme.spacing[4]};
        width: 100%;

        ul.pagination {
            li.page-item {
                margin-right: ${(props) => props.theme.spacing[2]};
                display: inline-block;

                button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: ${(props) => props.theme.spacing[10]};
                    width: ${(props) => props.theme.spacing[10]};

                    font-weight: 400;
                    font-size: ${(props) => props.theme.fontSizes[7]};
                    line-height: 140%;
                    text-decoration: none;
                    text-align: center;
                    color: ${(props) => props.theme.colors.text.gray700};
                    border-radius: ${(props) =>
                        props.theme.borderRadius.default};
                    svg {
                        font-size: ${(props) => props.theme.fontSizes[9]};
                    }
                }

                &.active {
                    button {
                        background-color: ${(props) =>
                            props.theme.colors.base.primary};
                        color: ${(props) => props.theme.colors.base.white};
                    }
                }

                &:hover {
                    button {
                        background-color: ${(props) =>
                            props.theme.colors.base.primary};
                        color: ${(props) => props.theme.colors.base.secondary};
                    }
                }
            }
        }

        .box-right {
            display: flex;
            flex-direction: row;
            align-items: center;

            select {
                display: flex;
                align-items: center;
                justify-content: center;

                font-weight: 400;
                font-size: ${(props) => props.theme.fontSizes[7]};
                line-height: 140%;
                text-decoration: none;
                text-align: center;
                color: ${(props) => props.theme.colors.text.gray700};
                margin-left: ${(props) => props.theme.spacing[4]};
                border: none;

                &:focus {
                    outline: 0;
                }
            }

            .description {
                font-size: ${(props) => props.theme.fontSizes[7]};
                font-weight: 400;
                color: ${(props) => props.theme.colors.text.gray700};
            }
        }
    }
`;
