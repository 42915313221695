import styled from 'styled-components';

export const InputTextWrap = styled.div`
    width: 100%;

    button {
        position: absolute;
        top: 50%;
        margin-top: -${(props) => props.theme.fontSizes[10]};

        right: ${(props) => props.theme.spacing[3]};
        height: ${(props) => props.theme.fontSizes[6]};

        svg {
            font-size: ${(props) => props.theme.fontSizes[6]};
            color: ${(props) => props.theme.colors.text.primary};
        }
    }
`;
